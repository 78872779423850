import React from 'react';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  standardColours,
  brandColours,
  brandFonts,
  fontWeights,
  fontSize,
  standardTransition,
} from '../../styles';
import { Link } from './Link';

const commonButtonStyles = () => {
  return css`
    display: inline-block;
    padding: 18px;
    color: ${({ altColours }) =>
      altColours ? brandColours.tertiary : standardColours.white};
    font-weight: ${fontWeights.medium};
    ${fontSize(14)};
    line-height: 1.3;
    text-align: center;
    background-color: ${({ altColours }) =>
      altColours ? standardColours.white : brandColours.primary};
    border-radius: 30px;
    transition: ${standardTransition('background-color')};

    ${minBreakpointQuery.medium`
      padding: 20px;
      ${fontSize(16)};
    `}

    &:hover {
      color: ${standardColours.white};
      background-color: ${brandColours.tertiary};
    }

    ${({ arrow }) => {
      if (arrow) {
        return css`
          position: relative;
          padding-right: 54px;

          ${minBreakpointQuery.medium`
            padding-right: 60px;
          `}

          &:after {
            content: '';
            position: absolute;
            top: 50%;
            right: 26px;
            height: 8px;
            width: 8px;
            border: 2px solid
              ${({ altColours }) =>
                altColours ? brandColours.tertiary : standardColours.white};
            border-bottom: none;
            border-left: none;
            transform: translateY(-50%) rotate(45deg);
            transition: ${standardTransition('transform')};
          }

          &:hover {
            &:after {
              transform: translateX(4px) translateY(-50%) rotate(45deg);
            }
          }
        `;
      }
    }};
  `;
};

const StyledButton = styled.button`
  ${commonButtonStyles()};
  font-family: ${brandFonts.primary};
  border: none;
  cursor: pointer;
`;

const StyledButtonLink = styled(Link)`
  ${commonButtonStyles()};
`;

export const Button = ({ arrow, altColours, children, ...props }) => {
  const ButtonComponent = props.to ? StyledButtonLink : StyledButton;

  return (
    <ButtonComponent arrow={arrow} altColours={altColours} {...props}>
      {children || 'Learn more'}
    </ButtonComponent>
  );
};
