export const buildUrl = (slug, page) => {
  let url = '/';

  if (typeof page !== 'string') {
    url +=
      process.env.GATSBY_GERMAN_WEBSITE !== 'TRUE' && page.locale !== 'en'
        ? `${page.locale}/`
        : '';

    if (page.treeParent) {
      if (page.treeParent.treeParent && page.treeParent.treeParent.treeParent) {
        url += `${page.treeParent.treeParent.treeParent.slug}/`;
      }

      if (page.treeParent.treeParent) {
        url += `${page.treeParent.treeParent.slug}/`;
      }

      url += `${page.treeParent.slug}/`;
    } else if (page.internal && page.internal.type) {
      if (page.internal.type === 'DatoCmsSystem') {
        url += `${
          page.locale === 'de'
            ? 'systeme'
            : page.locale === 'es'
            ? 'sistemas'
            : 'systems'
        }/`;
      } else if (page.internal.type === 'DatoCmsCaseStudy') {
        url += `${
          page.locale === 'de'
            ? 'fallstudien'
            : page.locale === 'es'
            ? 'casos-practicos'
            : 'case-studies'
        }/`;
      } else if (page.internal.type === 'DatoCmsNews') {
        url += `${
          page.locale === 'de'
            ? 'nachrichten'
            : page.locale === 'es'
            ? 'noticias'
            : 'news'
        }/`;
      }
    }
  }

  url += `${slug !== '/' ? `${slug.replace(/^\/|\/$/g, '')}/` : ''}`;

  return url;
};
