import React, { useContext } from 'react';
import { Button } from './Button';
import { translateString } from '../../utils';
import { SiteContext } from '../../context/SiteContext';

export const QuoteButton = ({ locale, ...props }) => {
  const { togglePopupFormOpen, setPopupFormType } = useContext(SiteContext);

  return (
    <Button
      onClick={() => {
        togglePopupFormOpen();
        setPopupFormType('quote');
      }}
      {...props}
    >
      {translateString('Request a quote', locale)}
    </Button>
  );
};
