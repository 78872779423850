export const standardColours = {
  black: '#000',
  white: '#FFF',
  transparent: 'rgba(255,255,255,0)',
};

export const brandColours = {
  primary: '#21478D', // darker blue
  secondary: '#EAF4FF', // lightest blue
  tertiary: '#1C3A6D', // darkest blue
  quaternary: '#2FCDEF', // dark blue
  quinary: '#B7E7F2', // light blue
  senary: '#95DCEC', // blue
  septenary: '#E8F7EA', // light green
  octonary: '#DCEBFC', // lighter blue
  nonary: '#5EB134', // green
  denary: '#9AA6BC', // blueish grey
  duodenary: '#E4EFFC', // blueish grey
};
