import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  brandColours,
  fontWeights,
  fontSize,
} from '../styles';
import GlobalStyle from '../styles/GlobalStyle';
import Header from './Header';
import Footer from './Footer';
import CookieNotice from './CookieNotice';
import PopupForm from './PopupForm';
import { getSingleLocaleFields, buildUrl } from '../utils';
import { SiteContext } from '../context/SiteContext';

const StyledLayout = styled.div`
  ${({ verticalText }) => {
    if (verticalText) {
      return css`
        ${minBreakpointQuery.xxlarge`
          position: relative;
        `}

        &:before {
          ${minBreakpointQuery.xxlarge`
            content: '${verticalText}';
            position: absolute;
            top: 50%;
            right: 10px;
            color: ${brandColours.denary};
            font-weight: ${fontWeights.bold};
            ${fontSize(120)};
            writing-mode: vertical-lr;
            opacity: 0.2;
            transform: translateY(-50%) rotate(180deg);
          `}

          ${minBreakpointQuery.xxxlarge`
            right: 20px;
            ${fontSize(160)};
          `}

          ${minBreakpointQuery.xxxxlarge`
            right: 30px;
            ${fontSize(200)};
          `}
        }
      `;
    }
  }};
`;

const Layout = ({
  seo,
  noIndex,
  locale,
  slugLocales,
  page,
  verticalText,
  children,
}) => {
  const { allDatoCmsSite } = useStaticQuery(graphql`
    query LayoutQuery {
      allDatoCmsSite {
        nodes {
          locale
          faviconMetaTags {
            ...GatsbyDatoCmsFaviconMetaTags
          }
        }
      }
    }
  `);

  const { faviconMetaTags } = getSingleLocaleFields(allDatoCmsSite, locale);

  const { isOverlayActive } = useContext(SiteContext);

  return (
    <StyledLayout verticalText={verticalText}>
      <HelmetDatoCms seo={seo} favicon={faviconMetaTags}>
        <html lang={locale} />
        {noIndex && <meta name="robots" content="noindex, follow" />}
        {slugLocales &&
          slugLocales.map(({ locale, value }, id) => (
            <link
              key={id}
              rel="alternate"
              hreflang={locale}
              href={`${process.env.GATSBY_SITE_URL}${buildUrl(value, {
                ...page,
                locale: locale,
              })}`}
            />
          ))}
      </HelmetDatoCms>
      <GlobalStyle isOverlayActive={isOverlayActive} />
      <Header locale={locale} />
      {children}
      <PopupForm locale={locale} />
      <Footer locale={locale} />
      <CookieNotice locale={locale} />
    </StyledLayout>
  );
};

export default Layout;
