import React from 'react';
import styled from 'styled-components';
import { brandColours, fontWeights, standardTransition } from '../../styles';
import { Link } from '../ui';
import { buildUrl } from '../../utils';

const StyledSubNavigationLinks = styled.div``;

const StyledHeading = styled.p`
  margin-bottom: 16px;
  font-weight: ${fontWeights.bold};
`;

const StyledItems = styled.ul``;

const StyledItem = styled.li`
  margin-top: 12px;

  &:first-child {
    margin-top: 0;
  }
`;

const StyledLink = styled(Link)`
  transition: ${standardTransition('color')};

  &:hover {
    color: ${brandColours.senary};
  }
`;

const SubNavigationLinks = ({ heading, items }) => (
  <StyledSubNavigationLinks>
    {heading && <StyledHeading>{heading}</StyledHeading>}
    <StyledItems>
      {items.map((item, i) => (
        <>
          {item.title ? (
            <StyledItem key={`sub-nav-link-${i}`}>
              <StyledLink to={item}>{item.title}</StyledLink>
            </StyledItem>
          ) : (
            <StyledItem key={`sub-nav-link-${i}`}>
              <StyledLink
                as="a"
                href={`${buildUrl(item.page.slug, item.page)}#${
                  item.hashtagId
                }`}
              >
                {item.text}
              </StyledLink>
            </StyledItem>
          )}
        </>
      ))}
    </StyledItems>
  </StyledSubNavigationLinks>
);

export default SubNavigationLinks;
