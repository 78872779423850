import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { minBreakpointQuery } from '../styles';
import { Link, Svg } from './ui';
import logo from '../images/logo.svg';
import altLogo from '../images/alt-logo.svg';

const StyledLogo = styled(Link)`
  display: inline-block;
`;

const StyledSvg = styled(Svg)`
  height: 40px;
  width: 120px;

  ${minBreakpointQuery.small`
    height: 50px;
    width: 150px;
  `}
`;

const Logo = ({ locale, alt, ...props }) => {
  const {
    datoCmsSite: {
      globalSeo: { siteName },
    },
  } = useStaticQuery(graphql`
    query LogoQuery {
      datoCmsSite {
        globalSeo {
          siteName
        }
      }
    }
  `);

  return (
    <StyledLogo
      to={
        process.env.GATSBY_GERMAN_WEBSITE !== 'TRUE' && locale !== 'en'
          ? `/${locale}/`
          : '/'
      }
      {...props}
    >
      <StyledSvg title={siteName} image={alt ? altLogo : logo} />
    </StyledLogo>
  );
};

export default Logo;
