import React from 'react';
import styled, { css } from 'styled-components';
import { minBreakpointQuery, brandColours } from '../styles';
import { Link } from './ui';
import ukFlag from '../images/uk-flag.img.svg';
import deFlag from '../images/de-flag.img.svg';
import esFlag from '../images/es-flag.img.svg';

const StyledLocaleSwitcher = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 5px;

  ${minBreakpointQuery.xlarge`
    margin: 10px;
  `}
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  margin: 5px;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledFlag = styled.span`
  position: relative;
  display: block;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  overflow: hidden;

  ${minBreakpointQuery.tsmall`
    height: 22px;
    width: 22px;
  `}

  ${minBreakpointQuery.smedium`
    height: 24px;
    width: 24px;
  `}

  ${minBreakpointQuery.large`
    height: 22px;
    width: 22px;
  `}

  ${({ active }) => {
    if (active) {
      return css`
        border: 2px solid ${brandColours.nonary};
      `;
    }
  }}
`;

const StyledImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const LocaleSwitcher = ({ locale, ...props }) => {
  const locales = [
    {
      name: 'English Site',
      code: 'en',
      url: process.env.GATSBY_MAIN_SITE_URL,
      flag: ukFlag,
    },
    {
      name: 'German Site',
      code: 'de',
      url: process.env.GATSBY_GERMAN_SITE_URL,
      flag: deFlag,
    },
    {
      name: 'Spanish Site',
      code: 'es',
      url: `${process.env.GATSBY_MAIN_SITE_URL}/es/`,
      flag: esFlag,
    },
  ];

  return (
    <StyledLocaleSwitcher {...props}>
      {locales.map(({ name, code, url, flag }, id) => (
        <StyledLink key={id} href={url}>
          <StyledFlag active={code === locale}>
            <StyledImage src={flag} alt={name} loading="lazy" />
          </StyledFlag>
        </StyledLink>
      ))}
    </StyledLocaleSwitcher>
  );
};

export default LocaleSwitcher;
