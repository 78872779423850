import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import {
  brandColours,
  standardColours,
  fontWeights,
  fontSize,
  minBreakpointQuery,
  maxBreakpointQuery,
} from '../styles';
import { Container, Link, Svg } from './ui';
import Logo from './Logo';
import { getSingleLocaleFields } from '../utils';
import backToTop from '../images/back-to-top.svg';

const StyledFooter = styled.footer`
  background-color: ${brandColours.primary};

  ${minBreakpointQuery.medium`
    position: relative;
  `}
`;

const StyledSectionTop = styled.div`
  padding: 50px 0 30px;

  ${minBreakpointQuery.medium`
    padding: 80px 0 50px;
  `}
`;

const StyledPhoneOuter = styled.div`
  margin: 30px 0;
  color: ${standardColours.white};
  padding: 16px 26px;
  background-color: ${brandColours.tertiary};
  border-radius: 30px;
  text-align: center;

  ${minBreakpointQuery.medium`
    float: right;
    margin: 0;
    background-color: transparent;
  `}
`;

const StyledPhoneInner = styled.p`
  font-weight: ${fontWeights.bold};
  ${fontSize(18)};

  ${minBreakpointQuery.medium`
    ${fontSize(16)};
  `}
`;

const StyledNavSection = styled.div`
  ${minBreakpointQuery.small`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  `}

  ${minBreakpointQuery.medium`
    grid-template-columns: repeat(2, 1fr) 2fr;
    grid-column-gap: 20px;
  `}
`;

const StyledNav = styled.div`
  color: ${standardColours.white};
  margin: 20px 0;
`;

const StyledHeading = styled.h3`
  position: relative;

  ${maxBreakpointQuery.small`
    cursor: pointer;
  `}

  &:after {
    content: ${({ display }) => (display ? "'-'" : "'+'")};
    position: absolute;
    top: 50%;
    right: 0;
    color: ${standardColours.white};
    ${fontSize(30)};
    transform: translateY(-50%);
    font-weight: ${fontWeights.regular};

    ${minBreakpointQuery.small`
      display: none;
    `}
  }
`;

const StyledNavInner = styled.ul`
  margin: 10px 0;
`;

const StyledNavItem = styled.li`
  display: ${({ display }) => (display ? 'block' : 'none')};
  color: ${brandColours.octonary};
  ${fontSize(14)};
  padding: 10px 0;

  ${minBreakpointQuery.small`
    display: block;
  `}
`;

const StyledAddresses = styled.div`
  ${minBreakpointQuery.medium`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
  `}

  ${minBreakpointQuery.large`
    column-gap: 30px;
  `}

  ${minBreakpointQuery.xlarge`
    column-gap: 40px;
  `}
`;

const StyledAddress = styled.p`
  color: ${brandColours.octonary};
  ${fontSize(14)};
  padding: 10px 0;
  margin: 10px 0;
  display: ${({ display }) => (display ? 'block' : 'none')};
  line-height: 1.8;

  ${minBreakpointQuery.small`
    display: block;
  `}
`;

const StyledLogos = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 50px;

  ${minBreakpointQuery.medium`
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
  `}
`;

const StyledImageContainer = styled.div`
  margin-bottom: 50px;
  display: flex;
  justify-content: center;

  ${minBreakpointQuery.medium`
    margin-bottom: 100px;
  `}
`;

const StyledImage = styled.img`
  padding: 0 8px;
  height: 52px;
  max-width: 100%;
`;

const StyledBackToTop = styled.button`
  appearance: none;
  background-color: ${brandColours.tertiary};
  border: none;
  width: 110px;
  height: 89px;
  cursor: pointer;
  margin: 0 auto;
  display: block;
  text-transform: uppercase;
  color: ${brandColours.octonary};
  ${fontSize(12)};
  line-height: 1.4;

  ${minBreakpointQuery.medium`
    position: absolute;
    bottom: 4px;
    left: 50%;
    transform: translateX(-50%);
  `}
`;

const StyledSvg = styled(Svg)`
  height: 15px;
  width: 10px;
`;

const StyledBackToTopText = styled.span`
  display: block;
  padding: 5px 15px 0;
`;

const StyledSectionBottom = styled.div`
  color: ${brandColours.octonary};
  border-top: 1px solid ${brandColours.tertiary};
  ${fontSize(14)};
  padding: 35px 0;
`;

const StyledSectionBottomContainer = styled(Container)`
  ${minBreakpointQuery.medium`
    display: flex;
    justify-content: space-between;
  `}
`;

const StyledCopyrightContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledCopyright = styled.p``;

const StyledSitemap = styled(Link)`
  text-decoration: underline;

  ${minBreakpointQuery.medium`
    padding-left: 15px;
  `}
`;

const StyledSignitureLink = styled.a`
  margin-top: 22px;
  display: block;
  text-align: center;

  ${minBreakpointQuery.medium`
    float: right;
    margin-top: 0;
    text-align: right;
    width: 130px;
  `}
`;

const Footer = ({ locale }) => {
  const {
    datoCmsSite: {
      globalSeo: { siteName },
    },
    allDatoCmsFooter,
    allDatoCmsContact,
  } = useStaticQuery(graphql`
    query FooterQuery {
      datoCmsSite {
        globalSeo {
          siteName
        }
      }
      allDatoCmsFooter {
        nodes {
          locale
          phoneNumber
          links {
            text
            page {
              ...LinkFragment
            }
          }
          policies {
            text
            page {
              ...LinkFragment
            }
          }
          associations {
            logo {
              alt
              url
            }
          }
        }
      }
      allDatoCmsContact {
        nodes {
          locale
          locations {
            address
          }
        }
      }
    }
  `);

  const { phoneNumber, links, policies, associations } = getSingleLocaleFields(
    allDatoCmsFooter,
    locale
  );

  const { locations } = getSingleLocaleFields(allDatoCmsContact, locale);

  const [activeItem, setActiveItem] = useState('links');

  return (
    <StyledFooter>
      <Container>
        <StyledSectionTop>
          <Logo locale={locale} alt={true} />
          <StyledPhoneOuter>
            <StyledPhoneInner>Call: {phoneNumber}</StyledPhoneInner>
          </StyledPhoneOuter>
        </StyledSectionTop>
        <StyledNavSection>
          <StyledNav>
            <StyledHeading
              display={activeItem === 'links'}
              onClick={() =>
                setActiveItem(() => {
                  if (activeItem === 'links') {
                    return false;
                  } else {
                    return 'links';
                  }
                })
              }
            >
              Links
            </StyledHeading>
            <StyledNavInner>
              {links.map(({ text, page }) => {
                return (
                  <StyledNavItem display={activeItem === 'links'}>
                    <Link to={page} title={text}>
                      {text}
                    </Link>
                  </StyledNavItem>
                );
              })}
            </StyledNavInner>
          </StyledNav>
          <StyledNav>
            <StyledHeading
              display={activeItem === 'policies'}
              onClick={() =>
                setActiveItem(() => {
                  if (activeItem === 'policies') {
                    return false;
                  } else {
                    return 'policies';
                  }
                })
              }
            >
              Policies
            </StyledHeading>
            <StyledNavInner>
              {policies.map(({ text, page }) => {
                return (
                  <StyledNavItem display={activeItem === 'policies'}>
                    <Link to={page} title={text}>
                      {text}
                    </Link>
                  </StyledNavItem>
                );
              })}
            </StyledNavInner>
          </StyledNav>
          <StyledNav>
            <StyledHeading
              display={activeItem === 'locations'}
              onClick={() =>
                setActiveItem(() => {
                  if (activeItem === 'locations') {
                    return false;
                  } else {
                    return 'locations';
                  }
                })
              }
            >
              Locations
            </StyledHeading>
            <StyledAddresses>
              {locations.map(({ address }) => {
                return (
                  <StyledAddress
                    display={activeItem === 'locations'}
                    dangerouslySetInnerHTML={{ __html: address }}
                  />
                );
              })}
            </StyledAddresses>
          </StyledNav>
        </StyledNavSection>
        {associations.length > 0 && (
          <StyledLogos>
            {associations.map(({ logo: { url, alt } }, i) => (
              <StyledImageContainer key={`association-logo-${i}`}>
                <StyledImage src={url} alt={alt} loading="lazy" />
              </StyledImageContainer>
            ))}
          </StyledLogos>
        )}
        <StyledBackToTop
          onClick={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
        >
          <StyledSvg image={backToTop} />
          <StyledBackToTopText>Back to top</StyledBackToTopText>
        </StyledBackToTop>
      </Container>
      <StyledSectionBottom>
        <StyledSectionBottomContainer>
          <StyledCopyrightContainer>
            <StyledCopyright>
              &copy; {new Date().getFullYear()} {siteName}
            </StyledCopyright>
            <StyledSitemap href="/sitemap.xml">Sitemap</StyledSitemap>
          </StyledCopyrightContainer>
          <StyledSignitureLink
            href="https://www.madebyswish.co.uk"
            target="_blank"
            rel="noopener"
          >
            madebyswish®
          </StyledSignitureLink>
        </StyledSectionBottomContainer>
      </StyledSectionBottom>
    </StyledFooter>
  );
};

export default Footer;
