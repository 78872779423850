export const getCollectionLocaleFields = (object, locale) => {
  return object.group.find(({ fieldValue }) => fieldValue === locale);
};

export const getSingleLocaleFields = (object, locale) => {
  return object.nodes.find(node => node.locale === locale);
};

export const translateString = (string, locale) => {
  const translations = [
    {
      en: 'Request a callback',
      de: 'Rückruf anfordern',
      es: 'Solicitar una llamada',
    },
    {
      en: 'View details',
      de: 'Details anzeigen',
      es: 'Ver detalles',
    },
    {
      en: 'Request a quote',
      de: 'Ein Angebot anfordern',
      es: 'Solicite un presupuesto',
    },
    {
      en: 'You can request a trial for this system',
      de: 'Sie können ein Funktionstest für Ihr Abwasser in Ihrem System anfordern',
      es: 'Puede solicitar una prueba de este sistema',
    },
    {
      en: 'Available for worldwide delivery',
      de: 'Verfügbar für weltweite Lieferung',
      es: 'Disponible para entrega en todo el mundo',
    },
    {
      en: 'System Description',
      de: 'Beschreibung des Systems',
      es: 'Descripción del sistema',
    },
    {
      en: 'Key Facts',
      de: 'Kernfakten',
      es: 'Datos clave',
    },
    {
      en: 'Other wastewater systems',
      de: 'Andere Abwassersysteme',
      es: 'Otros sistemas de aguas residuales',
    },
    {
      en: 'Contact',
      de: 'Kontaktieren',
      es: 'Contacte',
    },
    {
      en: 'Contact us',
      de: 'Kontaktieren Sie uns',
      es: 'Contacte con nosotros',
    },
    {
      en: 'Complete the following form and we’ll be in touch',
      de: 'Füllen Sie das folgende Formular aus und wir werden uns mit Ihnen in Verbindung setzen',
      es: 'Rellene el siguiente formulario y nos pondremos en contacto con usted',
    },
    {
      en: 'Your name',
      de: 'Ihr Name',
      es: 'Su nombre',
    },
    {
      en: 'Company name',
      de: 'Name des Unternehmens',
      es: 'Nombre de la empresa',
    },
    {
      en: 'Phone number',
      de: 'Telefon-Nummer',
      es: 'Número de teléfono',
    },
    {
      en: 'E-mail address',
      de: 'E-Mail Adresse',
      es: 'Dirección de correo electrónico',
    },
    {
      en: 'Your message',
      de: 'Ihre Nachricht',
      es: 'Su mensaje',
    },
    {
      en: 'Submit',
      de: 'Abschicken',
      es: 'Enviar',
    },
    {
      en: 'News',
      de: 'Nachrichten',
      es: 'Noticias',
    },
    {
      en: 'Latest Post',
      de: 'Letzter Beitrag',
      es: 'Última Publicación',
    },
    {
      en: 'Read post',
      de: 'Beitrag lesen',
      es: 'Leer publicación',
    },
    {
      en: 'Recent News',
      de: 'Aktuellen Nachrichten',
      es: 'Noticias recientes',
    },

    {
      en: 'View all news',
      de: 'Alle Nachrichten anzeigen',
      es: 'Ver todas las noticias',
    },

    {
      en: 'All Recent News',
      de: 'Alle aktuellen Nachrichten',
      es: 'Todas las noticias recientes',
    },
    {
      en: 'Case Studies',
      de: 'Fallstudien',
      es: 'Casos prácticos',
    },
    {
      en: 'View case study',
      de: 'Fallstudie ansehen',
      es: 'Ver casos prácticos',
    },

    {
      en: 'Recent Case Study',
      de: 'Aktuelle Fallstudie',
      es: 'Estudio de caso reciente',
    },

    {
      en: 'Are you ready to get started',
      de: 'Sind Sie bereit, loszulegen?',
      es: '¿Está listo para empezar?',
    },
    {
      en: 'Your message has been sent',
      de: 'Ihre Nachricht wurde versendet',
      es: 'Su mensaje ha sido envíado',
    },
    {
      en: 'Thank you, we’ll be in touch',
      de: 'Danke, wir bleiben in Kontakt',
      es: 'Gracias, estaremos en contacto',
    },
    {
      en: 'I’d like to talk to you about',
      de: 'Ich möchte gerne mit Ihnen darüber sprechen',
      es: 'Nos gustaría hablar sobre el tema',
    },
    {
      en: 'Please Select',
      de: 'Bitte Auswählen',
      es: 'Por favor, elija',
    },
    {
      en: 'General',
      de: 'Allgemeine',
      es: 'General',
    },
    {
      en: 'Info',
      de: 'Information',
      es: 'Información',
    },
    {
      en: 'Other',
      de: 'Sonstige',
      es: 'Otros',
    },
    {
      en: 'More Details',
      de: 'Weitere Details',
      es: 'Mas detalles',
    },
    {
      en: 'Featured Case Study',
      de: 'Vorgestellte Fallstudie',
      es: 'Supuesto práctico destacado',
    },
    {
      en: 'View all case studies',
      de: 'Alle fallstudien ansehen',
      es: 'Ver todos los supuestos prácticos',
    },
    {
      en: 'Case Study',
      de: 'Fallstudie',
      es: 'Supuesto práctico',
    },
    {
      en: 'Introduction',
      de: 'Vorstellung',
      es: 'Introducción',
    },
    {
      en: 'What We Did',
      de: 'Was wir bisher erreicht haben',
      es: 'Que hemos realizado',
    },
    {
      en: 'Feedback from the Client',
      de: 'Feedback vom Kunden',
      es: 'Comentarios del cliente',
    },
    {
      en: 'Systems',
      de: 'Systeme',
      es: 'Sistemas',
    },
    {
      en: 'View all Systems',
      de: 'Alle Systeme ansehen',
      es: 'Ver todos los sistemas',
    },
    {
      en: 'Learn more',
      de: 'Lernen sie mehr',
      es: 'Aprenda mas',
    },
    {
      en: 'Cookie Consent',
      de: 'Zustimmung zu den Cookies',
      es: 'Aceptar Cookies',
    },
    {
      en: 'This website uses cookies to give you the best experience',
      de: 'Diese Website nutzt Cookies, um Ihnen die bestmöglichen Erfahrungen zu bieten',
      es: 'Este sitio web utiliza cookies para ofrecerle una mejor experiencia',
    },
    {
      en: 'I’m ok with this',
      de: 'Ich bin damit einverstanden',
      es: 'Estoy de acuerdo',
    },
    {
      en: 'Applications & Industries',
      de: 'Anwendungen und Branchen',
      es: 'Aplicaciones e industrias',
    },
    {
      en: 'Packaging',
      de: 'Verpackung',
      es: 'Embalaje',
    },
  ];

  const translatedString = translations.find(({ en }) => en === string)[locale];

  return translatedString || string;
};
