import React from 'react';
import styled from 'styled-components';
import { minBreakpointQuery } from '../../styles';
import { Svg } from './Svg';
import dottedCircle from '../../images/dotted-circle.svg';

const StyledAnimatedCircles = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

const StyledCircle = styled(Svg)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  animation: rotate 60s linear infinite;

  &:last-child {
    padding: 60px;
    animation-direction: reverse;

    ${minBreakpointQuery.small`
      padding: 80px;
    `}

    ${minBreakpointQuery.large`
      padding: 100px;
    `}
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const AnimatedCircles = () => (
  <StyledAnimatedCircles>
    <StyledCircle image={dottedCircle} />
    <StyledCircle image={dottedCircle} />
  </StyledAnimatedCircles>
);
