import React from 'react';
import styled from 'styled-components';
import {
  breakpointSizes,
  minBreakpointQuery,
  brandColours,
  fontWeights,
  fontSize,
  fluidFontSize,
  standardTransition,
} from '../../styles';

export const StyledHtmlContent = styled.div`
  overflow: auto;

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 12px;
    font-weight: ${fontWeights.bold};
    line-height: 1.4;

    ${minBreakpointQuery.small`
      margin-bottom: 14px;
    `}
  }

  h2 {
    ${fluidFontSize(
      '25px',
      '45px',
      breakpointSizes.tiny,
      breakpointSizes.xxxxlarge
    )};
    line-height: 1.3;
  }

  h3 {
    ${fontSize(23)};
    line-height: 1.3;

    ${minBreakpointQuery.small`
      ${fontSize(25)};
    `}
  }

  h4 {
    ${fontSize(19)};

    ${minBreakpointQuery.small`
      ${fontSize(20)};
    `}
  }

  h5 {
    ${fontSize(18)};

    ${minBreakpointQuery.small`
      ${fontSize(19)};
    `}
  }

  h6 {
    ${fontSize(17)};

    ${minBreakpointQuery.small`
      ${fontSize(18)};
    `}
  }

  p {
    margin-bottom: 20px;
    ${fontSize(15)};
    line-height: 1.8;

    ${minBreakpointQuery.small`
      margin-bottom: 24px;
      ${fontSize(16)};
    `}
  }

  a {
    position: relative;
    color: ${brandColours.quaternary};
    border-bottom: 1px solid ${brandColours.quaternary};
    transition: ${standardTransition('color')},
      ${standardTransition('border-color')};

    &:hover {
      color: ${brandColours.nonary};
      border-bottom-color: ${brandColours.nonary};
    }
  }

  ul,
  ol {
    margin-bottom: 20px;

    ${minBreakpointQuery.small`
      margin-bottom: 24px;
    `}

    li {
      margin-bottom: 10px;
      ${fontSize(15)};
      line-height: 1.8;

      ${minBreakpointQuery.small`
        margin-bottom: 12px;
        ${fontSize(16)};
      `}
    }
  }

  ul {
    li {
      position: relative;
      padding-left: 20px;

      &:before {
        content: '';
        position: absolute;
        top: 8px;
        left: 0;
        display: inline-block;
        height: 10px;
        width: 10px;
        background-color: ${brandColours.quaternary};
        border-radius: 50%;
      }
    }
  }

  ol {
    list-style-type: decimal;

    li {
      margin-left: 16px;
    }
  }

  blockquote {
    position: relative;
    margin: 20px;
    padding: 10px 0 10px 40px;

    ${minBreakpointQuery.small`
      margin-top: 24px;
      margin-bottom: 24px;
      padding-left: 50px;
    `}

    ${minBreakpointQuery.large`
      padding-left: 60px;
    `}

    &:before {
      content: '“';
      position: absolute;
      top: 8px;
      left: 0;
      display: inline-block;
      color: ${brandColours.quaternary};
      font-family: sans-serif;
      ${fontSize(60)};
      line-height: 1;

      ${minBreakpointQuery.small`
        top: 4px;
        ${fontSize(80)};
      `}

      ${minBreakpointQuery.large`
        ${fontSize(100)};
      `}
    }

    p {
      font-style: italic;
    }
  }

  iframe {
    max-width: 100%;
  }

  table {
    width: 100%;

    td {
      padding: 10px 20px;
      line-height: 1.5;
      background-color: ${brandColours.octonary};
      border: 4px solid ${brandColours.secondary};

      ${minBreakpointQuery.large`
        padding: 15px 30px;
      `}
    }
  }
`;

export const HtmlContent = ({ html, ...props }) => (
  <StyledHtmlContent dangerouslySetInnerHTML={{ __html: html }} {...props} />
);
