import React from 'react';
import styled from 'styled-components';
import { minBreakpointQuery } from '../../styles';

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 30px;

  ${minBreakpointQuery.small`
    grid-gap: 40px;
  `}

  ${minBreakpointQuery.smedium`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${minBreakpointQuery.medium`
    grid-gap: 80px;
  `}

  ${minBreakpointQuery.mlarge`
    grid-template-columns: 1.125fr 1fr;
  `}

  ${minBreakpointQuery.large`
    grid-gap: 120px;
  `}

  ${minBreakpointQuery.xlarge`
    grid-template-columns: 1.25fr 1fr;
  `}
`;

export const Grid = ({ children, ...props }) => (
  <StyledGrid {...props}>{children}</StyledGrid>
);
