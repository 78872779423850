import React from 'react';
import styled from 'styled-components';
import { brandColours, fontWeights } from '../../styles';
import { ArrowLink } from '../ui';

const StyledSubNavigationCta = styled.div`
  padding: 30px;
  background-color: ${brandColours.duodenary};
`;

const StyledHeading = styled.p`
  font-weight: ${fontWeights.bold};
`;

const StyledText = styled.p`
  margin-top: 10px;
`;

const StyledLink = styled(ArrowLink)`
  margin-top: 15px;
`;

const SubNavigationCta = ({ heading, text, link }) => (
  <StyledSubNavigationCta>
    <StyledHeading>{heading}</StyledHeading>
    <StyledText>{text}</StyledText>
    <StyledLink to={link.page}>{link.text}</StyledLink>
  </StyledSubNavigationCta>
);

export default SubNavigationCta;
