import React from 'react';
import styled from 'styled-components';
import { minBreakpointQuery, brandColours, fontSize } from '../../styles';

const StyledOverline = styled.p`
  position: relative;
  margin-bottom: 10px;
  color: ${brandColours.quaternary};
  ${fontSize(14)};
  text-transform: uppercase;
  letter-spacing: 2px;

  ${minBreakpointQuery.tiny`
    padding-left: 35px;
  `}

  ${minBreakpointQuery.medium`
    margin-bottom: 14px;
    padding-left: 45px;
    ${fontSize(16)};
  `}

  &:before {
    ${minBreakpointQuery.tiny`
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      display: block;
      vertical-align: middle;
      height: 1px;
      width: 25px;
      background-color: ${brandColours.quaternary};
      transform: translateY(-50%);
    `}

    ${minBreakpointQuery.medium`
      width: 35px;
    `}
  }
`;

export const Overline = ({ children, ...props }) => (
  <StyledOverline {...props}>{children}</StyledOverline>
);
