import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  brandColours,
  brandFonts,
  fontSize,
  fontWeights,
  zIndexLayers,
} from '../styles';
import { slugify, translateString } from '../utils';

const StyledCookieNotice = styled.div`
  position: fixed;
  bottom: 20px;
  left: 0;
  padding: 30px 0;
  max-width: 430px;
  margin-right: 30px;
  margin-left: 30px;
  background-color: ${brandColours.secondary};
  z-index: ${zIndexLayers.fifth};
  border: 1px solid #d6e4f4;

  ${minBreakpointQuery.medium`
    padding: 35px 0;
  `}
`;

const StyledInner = styled.div`
  padding-left: 30px;
  padding-right: 30px;
`;

const StyledHeading = styled.h4`
  ${fontSize(22)};
  margin-bottom: 10px;
`;

const StyledText = styled.p`
  margin-bottom: 10px;

  ${minBreakpointQuery.small`
    margin-bottom: 15px;
  `}

  ${minBreakpointQuery.smedium`
    margin-bottom: 20px;
  `}

  ${minBreakpointQuery.medium`
    margin-bottom: 25px;
  `}
`;

const StyledButton = styled.button`
  font-family: ${brandFonts.primary};
  ${fontSize(18)};
  font-weight: ${fontWeights.medium};
  text-decoration: underline;
  border: none;
  background-color: ${brandColours.secondary};
  color: ${brandColours.primary};
  padding: 0;
  cursor: pointer;
`;

const CookieNotice = ({ locale }) => {
  const {
    datoCmsSite: { name },
  } = useStaticQuery(graphql`
    query CookieNoticeQuery {
      datoCmsSite {
        name
      }
    }
  `);

  const localStorageKey = `${slugify(name)}:accept-cookie-notice`;
  const [acceptedCookie, setAcceptedCookie] = useState();

  useEffect(() => {
    setAcceptedCookie(localStorage.getItem(localStorageKey) || false);
  }, [localStorageKey]);

  const acceptCookieNotice = () => {
    setAcceptedCookie(true);
    localStorage.setItem(localStorageKey, true);
  };

  return acceptedCookie === false ? (
    <StyledCookieNotice>
      <StyledInner>
        <StyledHeading>
          {translateString('Cookie Consent', locale)}
        </StyledHeading>
        <StyledText>
          {translateString(
            'This website uses cookies to give you the best experience',
            locale
          )}
        </StyledText>
        <StyledButton onClick={() => acceptCookieNotice()}>
          {translateString('I’m ok with this', locale)}
        </StyledButton>
      </StyledInner>
    </StyledCookieNotice>
  ) : (
    ''
  );
};

export default CookieNotice;
