import React from 'react';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  brandColours,
  fontSize,
  zIndexLayers,
  visuallyHidden,
} from '../styles';
import { CallbackButton } from './ui';
import SitewideNotice from './SitewideNotice';
import Logo from './Logo';
import Navigation from './Navigation';
import LocaleSwitcher from './LocaleSwitcher';

const StyledHeader = styled.header`
  position: sticky;
  top: 0;
  z-index: ${zIndexLayers.third};
  background-color: ${brandColours.secondary};
  border-bottom: 1px solid ${brandColours.octonary};
`;

const StyledInner = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 0 30px;

  ${minBreakpointQuery.xxlarge`
    padding-right: 40px;
    padding-left: 40px;
  `}

  ${minBreakpointQuery.xxxlarge`
    padding-right: 50px;
    padding-left: 50px;
  `}

  ${minBreakpointQuery.xxxxlarge`
    padding-right: 60px;
    padding-left: 60px;
  `}
`;

const StyledLogo = styled(Logo)`
  margin: 20px 0;

  ${minBreakpointQuery.large`
    order: 1;
  `};
`;

const StyledLocaleSwitcher = styled(LocaleSwitcher)`
  ${minBreakpointQuery.large`
    order: 3;
  `};
`;

const StyledNavigation = styled(Navigation)`
  ${minBreakpointQuery.large`
    order: 2;
  `};
`;

const StyledButton = styled(CallbackButton)`
  margin: 20px 0;

  ${maxBreakpointQuery.large`
    ${visuallyHidden()};
  `}

  ${minBreakpointQuery.large`
    order: 4;
    padding-top: 16px;
    padding-bottom: 16px;
    ${fontSize(14)};
    white-space: nowrap;
  `}
`;

const Header = ({ locale }) => (
  <StyledHeader>
    <SitewideNotice locale={locale} />
    <StyledInner>
      <StyledLogo locale={locale} />
      <StyledLocaleSwitcher locale={locale} />
      <StyledNavigation locale={locale} />
      <StyledButton locale={locale} />
    </StyledInner>
  </StyledHeader>
);

export default Header;
