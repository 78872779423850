import React from 'react';
import SlickSlider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  standardColours,
  brandColours,
  fontSize,
  zIndexLayers,
  standardTransition,
} from '../../styles';

const StyledSlider = styled(SlickSlider)`
  position: relative;

  .slick-arrow {
    position: absolute;
    bottom: 0;
    overflow: hidden;
    z-index: ${zIndexLayers.first};
    height: 40px;
    width: 40px;
    ${fontSize(0)};
    background: none;
    border: 1px solid ${brandColours.primary};
    border-radius: 50%;
    outline: none;
    cursor: pointer;
    transition: ${standardTransition('background-color')};

    ${minBreakpointQuery.medium`
      height: 60px;
      width: 60px;
    `}

    ${minBreakpointQuery.large`
      height: 80px;
      width: 80px;
    `}

    &:after {
      content: '➞';
      color: ${brandColours.primary};
      ${fontSize(14)};
      font-weight: bold;
      transition: ${standardTransition('color')};

      ${minBreakpointQuery.medium`
        ${fontSize(18)};
      `}

      ${minBreakpointQuery.large`
        ${fontSize(22)};
      `}
    }

    &.slick-prev {
      left: 0;
      transform: translateX(-100%) rotate(180deg);
    }

    &.slick-next {
      right: 0;
      transform: translateX(100%);
    }

    &:hover {
      background-color: ${brandColours.primary};

      &:after {
        color: ${standardColours.white};
      }
    }
  }

  .slick-dots {
    margin: 20px auto 0;
    height: 2px;
    width: 80%;
    max-width: 600px;

    ${minBreakpointQuery.tiny`
      margin-top: 30px;
    `}

    ${minBreakpointQuery.small`
      margin-top: 40px;
    `}

    ${minBreakpointQuery.medium`
      margin-top: 50px;
    `}

    ${minBreakpointQuery.large`
      margin-top: 60px;
    `}

    li {
      display: inline-block;
      vertical-align: top;
      padding: 0 5px;
      height: 2px;
      width: ${({ numSlides }) =>
        numSlides ? `calc(100% / ${numSlides})` : ''};

      ${minBreakpointQuery.small`
        padding-right: 10px;
        padding-left: 10px;
      `}

      button {
        display: block;
        height: 2px;
        width: 100%;
        text-indent: -9999em;
        background-color: ${brandColours.octonary};
        border: none;
        transition: ${standardTransition('background-color')};
      }

      &.slick-active {

        button {
          background-color: ${brandColours.primary};
          cursor: pointer;
        }
      }
    }
  }
`;

export const Slider = ({ numSlides, sliderOptions, children, ...props }) => (
  <StyledSlider numSlides={numSlides} {...sliderOptions} {...props}>
    {children}
  </StyledSlider>
);
