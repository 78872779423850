import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  brandColours,
  fontSize,
  standardTransition,
} from '../styles';
import { Link, Container } from './ui';
import SubNavigationCta from './navigation/SubNavigationCta';
import SubNavigationLinks from './navigation/SubNavigationLinks';
import { getCollectionLocaleFields } from '../utils';

const StyledNavigation = styled.nav`
  ${minBreakpointQuery.large`
    width: 70%;
  `}
`;

const StyledToggle = styled.button`
  ${maxBreakpointQuery.large`
    position: relative;
    margin: 0;
    padding: 2px;
    height: 100%;
    background: none;
    border: none;
    outline: 0;
    cursor: pointer;
  `}

  ${minBreakpointQuery.large`
    display: none;
  `}

  span {
    ${maxBreakpointQuery.large`
      display: block;
      height: 4px;
      width: 28px;
      margin: 6px 0;
      background-color: ${brandColours.tertiary};
      border-radius: 2px;
    `}

    ${maxBreakpointQuery.small`
      height: 3px;
      width: 21px;
    `}

    ${({ displayNav }) => {
      if (displayNav) {
        return css`
          ${maxBreakpointQuery.large`
            opacity: 0;
          `}

          &:first-child,
          &:last-child {
            ${maxBreakpointQuery.large`
              opacity: 1;
            `}
          }

          &:first-child {
            ${maxBreakpointQuery.large`
              transform: translateY(10px) rotate(45deg);
            `}

            ${maxBreakpointQuery.small`
              transform: translateY(9px) rotate(45deg);
            `}
          }

          &:last-child {
            ${maxBreakpointQuery.large`
              transform: translateY(-10px) rotate(-45deg);
            `}

            ${maxBreakpointQuery.small`
              transform: translateY(-9px) rotate(-45deg);
            `}
          }
        `;
      }
    }}
  }
`;

const StyledList = styled.ul`
  ${maxBreakpointQuery.large`
    position: absolute;
    left: 0;
    display: ${({ displayNav }) => (displayNav ? 'block' : 'none')};
    overflow: auto;
    margin-top: 1px;
    padding-top: 20px;
    padding-bottom: 20px;
    height: 100vh;
    width: 100%;
    background-color: ${brandColours.secondary};
  `}

  ${minBreakpointQuery.large`
    display: flex;
    justify-content: space-around;
    margin-left: 30px;
    margin-right: 30px;
    height: 100%;
  `}
`;

// moved up for hover
const StyledSubNav = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  background-color: ${brandColours.secondary};
  border-top: 1px ${brandColours.octonary} solid;
  border-bottom: 1px ${brandColours.octonary} solid;

  ${maxBreakpointQuery.large`
    display: none;
  `}

  ${minBreakpointQuery.large`
    left: -9999em;
    padding: 30px 0 60px;
  `}

  ${minBreakpointQuery.xlarge`
    padding-top: 40px;
    padding-bottom: 80px;
  `}

  ${minBreakpointQuery.xxlarge`
    padding-bottom: 120px;
  `}
`;

const StyledItem = styled.li`
  ${maxBreakpointQuery.large`
    margin-top: 10px;
  `}

  ${minBreakpointQuery.large`
    display: inline-block;
    margin-left: 5px;
  `}

  ${minBreakpointQuery.xxlarge`
    margin-left: 10px;
  `}

  ${minBreakpointQuery.xxxxlarge`
    margin-left: 15px;
  `}

  &:first-child {
    ${maxBreakpointQuery.large`
      margin-top: 0;
    `}

    ${minBreakpointQuery.large`
      margin-left: 0;
    `}
  }

  &:hover {
    ${StyledSubNav} {
      ${minBreakpointQuery.large`
        left: 0;
      `}
    }
  }
`;

const StyledLink = styled(Link)`
  padding: 10px;
  transition: ${standardTransition('color')};

  ${maxBreakpointQuery.large`
    display: block;
    padding-right: 30px;
    padding-left: 30px;
    ${fontSize(20)};
  `}

  ${minBreakpointQuery.large`
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;
    height: 100%;
    text-align: center;
  `}

  &:before {
    ${minBreakpointQuery.large`
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      height: 3px;
      width: 100%;
      background-color: ${brandColours.senary};
      transform: translateY(100%);
      transition: ${standardTransition('transform')};
    `}
  }

  &:hover,
  &.current-page {
    &:before {
      ${minBreakpointQuery.large`
        transform: translateY(0);
      `}
    }
  }
`;

const StyledSubNavList = styled.ul`
  ${minBreakpointQuery.large`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
  `}
`;

const Navigation = ({ locale, ...props }) => {
  const { allDatoCmsNavigation } = useStaticQuery(graphql`
    query NavigationQuery {
      allDatoCmsNavigation(sort: { fields: position }) {
        group(field: locale) {
          fieldValue
          nodes {
            id
            page {
              ...LinkFragment
            }
            text
            subNavigationBlocks {
              ... on DatoCmsSubNavigationCtaBlock {
                id
                model {
                  apiKey
                }
                heading
                text
                linkPage {
                  ...LinkFragment
                }
                linkText
              }
              ... on DatoCmsSubNavigationLinksBlock {
                id
                model {
                  apiKey
                }
                heading
                links {
                  ...LinkFragment
                  ... on DatoCmsAnchorUrl {
                    internal {
                      type
                    }
                    text
                    page {
                      ...LinkFragment
                    }
                    hashtagId
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const { nodes } = getCollectionLocaleFields(allDatoCmsNavigation, locale);

  const [displayNav, setDisplayNav] = useState(false);

  return (
    <StyledNavigation {...props}>
      <StyledToggle
        aria-label="Open / Close Navigation"
        displayNav={displayNav}
        onClick={() => {
          setDisplayNav(!displayNav);
        }}
      >
        <span />
        <span />
        <span />
      </StyledToggle>
      <StyledList displayNav={displayNav}>
        {nodes.map(
          ({ id, text, page, subNavigationBlocks }) =>
            page && (
              <StyledItem key={id}>
                <StyledLink to={page} activeClassName="current-page">
                  {text}
                </StyledLink>
                {subNavigationBlocks.length > 0 && (
                  <StyledSubNav>
                    <Container>
                      <StyledSubNavList>
                        {subNavigationBlocks.map(subNavigationBlock => (
                          <li key={subNavigationBlock.id}>
                            {subNavigationBlock.model.apiKey ===
                              'sub_navigation_cta_block' && (
                              <SubNavigationCta
                                heading={subNavigationBlock.heading}
                                text={subNavigationBlock.text}
                                link={{
                                  page: subNavigationBlock.linkPage,
                                  text: subNavigationBlock.linkText,
                                }}
                              />
                            )}
                            {subNavigationBlock.model.apiKey ===
                              'sub_navigation_links_block' && (
                              <SubNavigationLinks
                                heading={subNavigationBlock.heading}
                                items={subNavigationBlock.links}
                              />
                            )}
                          </li>
                        ))}
                      </StyledSubNavList>
                    </Container>
                  </StyledSubNav>
                )}
              </StyledItem>
            )
        )}
      </StyledList>
    </StyledNavigation>
  );
};

export default Navigation;
