import React from 'react';
import styled from 'styled-components';
import {
  standardColours,
  brandColours,
  fontWeights,
  fontSize,
  standardTransition,
} from '../../styles';
import { Link } from './Link';

const StyledArrowLink = styled(Link)`
  position: relative;
  display: inline-block;
  padding-right: 30px;
  color: ${brandColours.quaternary};
  font-weight: ${fontWeights.bold};
  ${fontSize(16)};
  transition: ${standardTransition('color')};

  &:before,
  &:after {
    content: '';
    position: absolute;
  }

  &:before {
    top: 0;
    right: 0;
    height: 20px;
    width: 20px;
    background-color: ${brandColours.quaternary};
    border-radius: 50%;
    transition: ${standardTransition('background-color')};
  }

  &:after {
    top: 7px;
    right: 8px;
    height: 6px;
    width: 6px;
    border: 1px solid ${standardColours.white};
    border-bottom: 0;
    border-left: 0;
    transform: rotate(45deg);
  }

  &:hover {
    color: ${brandColours.nonary};

    &:before {
      background-color: ${brandColours.nonary};
    }
  }
`;

export const ArrowLink = ({ arrow, children, ...props }) => (
  <StyledArrowLink {...props}>{children || 'Learn more'}</StyledArrowLink>
);
